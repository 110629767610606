import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, signal } from '@angular/core';
import { TitleTab } from './interfaces/TitleTab';

@Component({
  selector: 'app-tabs-manager',
  templateUrl: './tabs-manager.component.html',
  styleUrl: './tabs-manager.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsManagerComponent implements OnChanges {
  @Input() public titles: TitleTab[] = [];
  @Input() public templateContent: TemplateRef<any>;
  @Output() public tabIndexSelected = new EventEmitter<number>();
  @Output() public tabIndexRemove = new EventEmitter<number>();

  protected indexSelected = signal(0);
  private countTitles = 0

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.titles) {
      if (this.countTitles + 1 === changes.titles.currentValue.length) {
        this.indexSelected.set(changes.titles.currentValue.length - 1);
        this.tabIndexSelected.emit(this.indexSelected())
      }
      this.countTitles = changes.titles.currentValue.length;
    }
  }


  public removeTab(index: number) {
    if (index !== 0) {
      this.indexSelected.set(index - 1);
      this.tabIndexSelected.emit(index - 1);
    }
    this.tabIndexRemove.emit(index);
  }
}
