import {Inject, Injectable, OnDestroy, Signal, signal} from '@angular/core';
import {EMPTY, Observable, Subscription, of, zip} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {HttpClient} from '@angular/common/http';
import {CurrentConditions} from './current-conditions/current-conditions.type';
import {ConditionsAndZip} from './conditions-and-zip.type';
import {Forecast} from './forecasts-list/forecast.type';
import { LocationService } from './location.service';
import { CacheWeatherToken } from 'shared/tokens/CacheWeatherToken';
import { CacheService } from 'shared/services/cache/cache.service';
import { CacheForecastToken } from 'shared/tokens/CacheForecastToken';

@Injectable()
export class WeatherService implements OnDestroy {

  static URL = 'https://api.openweathermap.org/data/2.5';
  static APPID = '5a4b2d457ecbef9eb2a71e480b947604';
  static ICON_URL = 'https://raw.githubusercontent.com/udacity/Sunshine-Version-2/sunshine_master/app/src/main/res/drawable-hdpi/';
  private currentConditions = signal<ConditionsAndZip[]>([]);

  private subs = new Subscription();

  constructor(private http: HttpClient, private locationService: LocationService,
    @Inject(CacheWeatherToken) private cacheWeatherService: CacheService<string, CurrentConditions>,
    @Inject(CacheForecastToken) private cacheForecastService: CacheService<string, Forecast>
  ) {
    this.subs.add(
      this.locationService.locations$.subscribe(locations => {
        for (const location of locations) {
          const hasCurrentCondition = this.currentConditions().some(currentCondition => currentCondition.zip === location);
          if (!hasCurrentCondition) {
            this.addCurrentConditions(location);
          }
        }

        const currentConditionsRemove = this.currentConditions()
          .filter(currentCondition => !locations.includes(currentCondition.zip))
          .map(currentCondition => currentCondition.zip);
        for (const zipcodeRemove of currentConditionsRemove) {
          this.removeCurrentConditions(zipcodeRemove);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  addCurrentConditions(zipcode: string): void {
    if (this.cacheWeatherService.isValidCache(zipcode) && this.cacheWeatherService.getCache(zipcode) != null) {
      const hasCurrentConditions = this.currentConditions().some(currentCondition => currentCondition.zip === zipcode);
      if (hasCurrentConditions) {
        this.currentConditions.update(conditions => conditions.map(currentCondition => currentCondition.zip === zipcode
          ? { zip: zipcode, data: this.cacheWeatherService.getCache(zipcode) }
          : currentCondition)
        );
      } else {
        this.currentConditions.update(conditions => [...conditions, { zip: zipcode, data: this.cacheWeatherService.getCache(zipcode) }]);
      }
    } else {
      // Here we make a request to get the current conditions data from the API. Note the use of backticks and an expression to insert the zipcode
      this.http.get<CurrentConditions>(`${WeatherService.URL}/weather?zip=${zipcode},us&units=imperial&APPID=${WeatherService.APPID}`).pipe(
        catchError(response => {
          if (response.status === 404) {
            return of(null)
          }
        })
      ).subscribe(data => {
        if (data == null) {
          this.cacheWeatherService.removeCache(zipcode);
          this.locationService.removeLocation(zipcode);
          alert(`The location with zipcode ${zipcode} don't exists`)
          return;
        }
        this.cacheWeatherService.addCache(zipcode, data);
        this.currentConditions.update(conditions => [...conditions, {zip: zipcode, data}])
      });
    }
  }

  removeCurrentConditions(zipcode: string) {
    this.currentConditions.update(conditions => {
      const conditionsClone = [...conditions];
      for (let i in conditionsClone) {
        if (conditionsClone[i].zip == zipcode)
          conditionsClone.splice(+i, 1);
      }
      return conditionsClone;
    });
    this.cacheWeatherService.removeCache(zipcode);
  }

  getCurrentConditions(): Signal<ConditionsAndZip[]> {
    return this.currentConditions.asReadonly();
  }

  getForecast(zipcode: string): Observable<Forecast> {
    if (this.cacheForecastService.isValidCache(zipcode)) {
      return of(this.cacheForecastService.getCache(zipcode));
    } else {
      // Here we make a request to get the forecast data from the API. Note the use of backticks and an expression to insert the zipcode
      return this.http.get<Forecast>(`${WeatherService.URL}/forecast/daily?zip=${zipcode},us&units=imperial&cnt=5&APPID=${WeatherService.APPID}`).pipe(
        tap(forecast => this.cacheForecastService.addCache(zipcode, forecast))
      );
    }

  }

  getWeatherIcon(id): string {
    if (id >= 200 && id <= 232)
      return WeatherService.ICON_URL + "art_storm.png";
    else if (id >= 501 && id <= 511)
      return WeatherService.ICON_URL + "art_rain.png";
    else if (id === 500 || (id >= 520 && id <= 531))
      return WeatherService.ICON_URL + "art_light_rain.png";
    else if (id >= 600 && id <= 622)
      return WeatherService.ICON_URL + "art_snow.png";
    else if (id >= 801 && id <= 804)
      return WeatherService.ICON_URL + "art_clouds.png";
    else if (id === 741 || id === 761)
      return WeatherService.ICON_URL + "art_fog.png";
    else
      return WeatherService.ICON_URL + "art_clear.png";
  }

}
