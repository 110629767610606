// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host() {
    display: flex;
    flex-direction: column;
}

.tabs {
    display: flex;
    overflow-x: auto;
}

.tab-title {
    background-color: #5b7aab;
    color: white;
    padding: 0rem 1rem;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
}

.tab-title.active {
    background-color: #5e8dd9;
}

.tab-title:not(:first-child) {
    margin-left: 3px;
}

.close-tab {
    color: white;
    text-decoration: none;
    padding-left: 1rem;
    font-size: 1.8rem;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/tabs-manager/tabs-manager.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,qBAAqB;IACrB,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[":host() {\n    display: flex;\n    flex-direction: column;\n}\n\n.tabs {\n    display: flex;\n    overflow-x: auto;\n}\n\n.tab-title {\n    background-color: #5b7aab;\n    color: white;\n    padding: 0rem 1rem;\n    display: flex;\n    align-items: center;\n    text-wrap: nowrap;\n}\n\n.tab-title.active {\n    background-color: #5e8dd9;\n}\n\n.tab-title:not(:first-child) {\n    margin-left: 3px;\n}\n\n.close-tab {\n    color: white;\n    text-decoration: none;\n    padding-left: 1rem;\n    font-size: 1.8rem;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
