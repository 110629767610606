import { Injectable} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export const LOCATIONS : string = "locations";

@Injectable()
export class LocationService {
  public locations$: Observable<string[]>;
  private _locations$ = new BehaviorSubject<string[]>([]);

  constructor() {
    this.locations$ = this._locations$.asObservable();
    let locString = localStorage.getItem(LOCATIONS);
    if (locString)
      this._locations$.next(JSON.parse(locString));
  }

  addLocation(zipcode : string) {
    if (this._locations$.value.includes(zipcode)) {
      return;
    }
    this._locations$.next([...this._locations$.value, zipcode]);
    localStorage.setItem(LOCATIONS, JSON.stringify(this._locations$.value));
  }

  removeLocation(zipcode : string) {
    this._locations$.next(this._locations$.value.filter(location => location !== zipcode))
    localStorage.setItem(LOCATIONS, JSON.stringify(this._locations$.value));
  }
}
