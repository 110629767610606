/**
 * Convert hours to seconds.
 * @param {number} hours - The hours you want to convert.
 * @returns {number} The number of seconds.
 */
export function hoursToSeconds(hours: number): number {
  return hours * 3600;
}

/**
 * Convert minutes to seconds.
 * @param {number} minutes - The minutes you want to convert.
 * @returns {number} The number of seconds.
 */
export function minutesToSeconds(minutes: number): number {
  return minutes * 60;
}
