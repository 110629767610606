import { Provider } from "@angular/core";
import { CacheWeatherToken } from "./tokens/CacheWeatherToken";
import { CacheService } from "./services/cache/cache.service";
import { CurrentConditions } from "app/current-conditions/current-conditions.type";
import { hoursToSeconds } from "./utils/time.utils";
import { CacheForecastToken } from "./tokens/CacheForecastToken";
import { Forecast } from "app/forecasts-list/forecast.type";

export const providers: Provider[] = [
    {
        provide: CacheWeatherToken,
        useFactory: () => new CacheService<string, CurrentConditions>('weather', hoursToSeconds(2))
    },
    {
        provide: CacheForecastToken,
        useFactory: () => new CacheService<string, Forecast>('forecast', hoursToSeconds(2))
    }
]
