import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'concatArray'
})
export class ConcatArrayPipe implements PipeTransform {

  transform<T>(elements: T[], otherElements: T[]): T[] {
    return elements.concat(otherElements);
  }

}
