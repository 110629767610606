import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsManagerComponent } from './tabs-manager/tabs-manager.component';


@NgModule({
  declarations: [
    TabsManagerComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TabsManagerComponent
  ]
})
export class ComponentsModule { }
