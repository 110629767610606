import {Component, computed, inject, signal, Signal} from '@angular/core';
import {WeatherService} from "../weather.service";
import {LocationService} from "../location.service";
import {Router} from "@angular/router";
import {ConditionsAndZip} from '../conditions-and-zip.type';
import { TitleTab } from 'shared/components/tabs-manager/interfaces/TitleTab';

@Component({
  selector: 'app-current-conditions',
  templateUrl: './current-conditions.component.html',
  styleUrls: ['./current-conditions.component.css']
})
export class CurrentConditionsComponent {

  protected weatherService = inject(WeatherService);
  private router = inject(Router);
  protected locationService = inject(LocationService);
  protected currentConditionsByZip: Signal<ConditionsAndZip[]> = this.weatherService.getCurrentConditions();
  protected currentConditionsNames = computed<TitleTab[]>(() => this.currentConditionsByZip()
    .map(x => ({
      title: `${x.data.name} (${x.zip})`,
      hasRemove: true
    })));
  protected index = signal(0);
  protected currentCondition = computed(() => this.index() === 0 ? null : this.currentConditionsByZip()[this.index() - 1]);
  protected readonly helloWorldTab: TitleTab = { title: 'Hello world', hasRemove: false};

  showForecast(zipcode : string){
    this.router.navigate(['/forecast', zipcode])
  }

  public removeIndex(index: number) {
    if (index === 0) {
      return;
    }
    this.locationService.removeLocation(this.currentConditionsByZip()[index - 1].zip);
  }
}
