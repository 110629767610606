import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConcatArrayPipe } from './pipes/concat-array/concat-array.pipe';

const pipes = [
  ConcatArrayPipe
];


@NgModule({
  declarations: [
    ...pipes
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ...pipes
  ]
})
export class SharedModule { }
