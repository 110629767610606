import { Injectable, Type } from '@angular/core';
import { CacheValue } from 'shared/interfaces/CacheValue';

@Injectable({
  providedIn: 'root'
})
export class CacheService<TypeKey, TypeValue> {
  private cache = new Map<TypeKey, CacheValue<TypeValue>>();

  constructor(private name: string, private secondsValid: number) {
    this.loadCache();
  }

  public addCache(key: TypeKey, value: TypeValue): void {
    this.cache.set(key, {
      createdAt: new Date(),
      value
    });
    this.saveCache();
  }

  public isValidCache(key: TypeKey): boolean {
    if (!this.cache.has(key)) {
      return false;
    }
    const secondsDiff = (new Date().getTime() - this.cache.get(key).createdAt.getTime()) / 1000;
    return secondsDiff <= this.secondsValid;
  }

  public getCache(key: TypeKey): TypeValue {
    return this.cache.get(key).value;
  }

  public removeCache(key: TypeKey): void {
    this.cache.delete(key);
    this.saveCache();
  }

  private loadCache() {
    const cacheJSON = localStorage.getItem(`cache_${this.name}`);
    if (cacheJSON) {
      const objectJSON = JSON.parse(cacheJSON);
      Object.keys(objectJSON).forEach(key => {
        this.cache.set(key as TypeKey, {
          createdAt: new Date(objectJSON[key].createdAt),
          value: objectJSON[key].value
        });
      });
    }
  }

  private saveCache() {
    const objectJSON: { [key: string]: any } = {};
    Array.from(this.cache.entries()).forEach(([key, value]) => {
      objectJSON[key.toString()] = value;
    });
    localStorage.setItem(`cache_${this.name}`, JSON.stringify(objectJSON));
  }
}
